.tabBody {
  display: flex;
  width: 100%;
  .left {
    flex: 0 0 140px;
  }
  .right {
    flex: 1;
    padding-bottom: 75px;
    padding-left: 24px;
    overflow-y: hidden;
  }
}
