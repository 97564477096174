@import '@/style/mixin.less';

.speech-side {
  width: 210px;
  height: calc(100vh - 65px);
  background: #ffffff;
  box-shadow: 1px 1px 4px 0 #0000001a;

  .side-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 16px 0;

    &::before {
      position: absolute;
      top: 16px;
      bottom: 16px;
      left: 0;
      display: block;
      width: 4px;
      background: #4288ff;
      content: '';
    }

    .side {
      flex-shrink: 0;
      padding: 10px 42px 10px 18px;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      background-image: linear-gradient(227deg, #83ddf8 0%, #4c83ee 90%);
      border-radius: 0 100px 100px 0;
      .addFontSize(14px);
    }
  }

  .side-container {
    // padding-bottom: 16px;
    height: calc(100% - 78px);
    padding-left: 24px;
    overflow-y: auto;

    .scroll-bar();

    .side-step {
      .step-title {
        display: flex;
        align-items: center;
        min-height: 22px;
        margin-bottom: 6px;
        color: #303030;
        font-size: 14px;
        cursor: pointer;
        .addFontSize(14px);

        &:first-child,
        &:nth-child(2) {
          margin-bottom: 6px;
        }

        .title-count {
          margin-left: 8px;
          padding: 4px;
          color: #4288ff;
          font-size: 14px;
          line-height: 14px;
          background: #4288ff29;
          border-radius: 4px;
          .addFontSize(14px);
        }
      }

      .step-title-active {
        color: rgba(66, 136, 255, 1);
        font-weight: 600;
        font-size: 14px;
        .addFontSize(14px);
      }

      .container {
        position: relative;
        margin-bottom: 6px;
        margin-left: 4px;
        padding: 10px 0 10px 14px;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 1px;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
          content: '';
        }

        .step-item {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-bottom: 12px;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            position: absolute;
            top: 50%;
            left: -14px;
            display: block;
            width: 1px;
            height: 0;
            background-color: #4288ff;
            transform: translateY(-50%);
            transition: all 0.5s;
            content: '';
          }
        }

        .label {
          display: flex;
          gap: 4px 4px;
          align-items: center;
        }

        .label-remarks {
          display: inline-block;
          max-width: 70px;
          padding-top: 4px;
          padding-left: 12px;
          overflow: hidden;
          color: rgba(11, 33, 69, 0.45);
          font-size: 14px;
          line-height: 20px;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          cursor: pointer;

          &:hover {
            color: rgba(48, 48, 48, 1);
          }
        }

        .label-remarks-active {
          color: rgba(48, 48, 48, 1);
        }

        .step-item-active {
          &::before {
            height: 100%;
          }
        }

        .step {
          position: relative;
          display: inline-block;
          flex-shrink: 0;
          max-width: 150px;
          padding: 4px 12px;
          overflow: hidden;
          color: rgba(48, 48, 48, 1);
          font-size: 14px;
          white-space: nowrap;
          text-overflow: ellipsis;
          word-break: break-all;
          cursor: pointer;
          .addFontSize(14px);
        }

        .step-active {
          color: rgba(66, 136, 255, 1);
          font-weight: 500;
          font-size: 14px;
          background: rgba(66, 136, 255, 0.16);
          border-radius: 4px;
          .addFontSize(14px);
        }

        .step-ct {
          display: -webkit-box;
          max-width: 40px;
          // margin-left: 5px;
          padding: 2px 4px;
          overflow: hidden;
          color: #ff8142;
          font-size: 14px;
          word-break: break-all;
          background: #ff944229;
          border-radius: 4px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          .addFontSize(14px);
        }
      }
    }
  }
}
