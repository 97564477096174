@import '@/style/var.less';

.compress-image {
  position: relative;

  .image-card {
    width: 100px;
    height: 100px;
    overflow: hidden;
    background-color: #cdcdcd;
    border-radius: 4px;
  }

  .mask-tip {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 2px 4px;
    font-size: 12px;
    text-align: justify;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .mask {
    border-radius: 4px;
  }

  .mask-visibility {
    opacity: 1;
  }

  .compress-progress {
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 100;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 600;

    .progress {
      display: flex;
      align-items: center;
      padding: 2px 8px;
      color: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      text-align: center;
      // border: 1px solid #ffffff;
      background: @primary-color;
      border: 1px solid @primary-color;
      border-radius: 421.33px;
    }

    .wait {
      padding: 2px 8px;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      background: #f53f3f;
      border: 1px solid #f53f3f;
      border-radius: 421.33px;
    }
  }

  .compress-tip {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 4px 4px;
    color: #fff;
    font-size: 12px;
    text-align: left;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .error-tip {
    background: #fa5555cc;
  }
}
