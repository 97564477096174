.inspection-item {
  display: flex;
  gap: 8px 8px;
  align-items: center;
  padding: 8px 0 8px 8px;
  border-top: 1px solid #d9d9d9;

  .inspection-tool {
    display: flex;
    flex-shrink: 0;
    gap: 12px;
    justify-content: flex-end;
    width: 85px;
    max-width: 120px;
    padding-right: 13px;
  }
}

:global(#zhInspectionItems_help) {
  display: none;
}

:global(.speech-form-item .ant-form-item-explain-error) {
  display: none;
}

.inspect-table-form {
  .inspect-table {
    overflow: hidden;
    border: 1px solid #d9d9d9;
    border-radius: 6px;

    .table-header {
      display: flex;
      background-color: #f4f8fe;
    }

    .table-header-td {
      flex: 1 1 238px;
      padding: 13px;
      color: #0b2145;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
    }

    .td-action {
      width: 85px;
      max-width: 120px;
      padding: 13px;
      font-weight: 500;
      text-align: right;
    }

    .table-container {
      display: flex;
      flex-direction: column;
    }

    // .container-padding {
    //   padding: 8px 0 8px 8px;
    // }

    .table-footer {
      display: flex;
      justify-content: center;
      padding: 8px 0;
      border-top: 1px solid #d9d9d9;
    }
  }
}
