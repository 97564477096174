.pic-smaller-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  text-align: center;
  vertical-align: top;
  background-color: rgba(0, 0, 0, 0.02);
  border: 1px dashed #d9d9d9;
  border-radius: 8px;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #4288ff;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
